import logo from "./oneclick-logo.svg";

export default {
  logo,
  brand: {
    awards: [],
  },
  renewalBenefits: {
    home: [
      "<strong>FREE</strong> Accidental Damage",
      "UK Based Contact Centre",
      "Online Portal Available 24/7",
      "24 Hour Claims",
    ],
    motor: [
      "Uninsured Driver Promise",
      "Online Portal Available 24/7",
      "24 Hour Claims & Recovery Service",
      "UK Based Contact Centre",
    ],
    chunk: 2,
  },
  features: {
    tradingName: true,
    stats: {
      percentage: 61.31,
      customers: 21615,
    },
    telephoneNumbers: {
      renewals: {
        motor: {
          inbound: "",
          outbound: "",
        },
        home: {
          inbound: "",
          outbound: "",
        },
      },
    },
  },
  theme: {
    preset: {
      primitive: {
        blue: {
          50: "#eef5fc",
          100: "#abbed1",
          200: "#94c2f2",
          300: "#65a7ec",
          400: "#358ce6",
          500: "#0671e0",
          600: "#0560be",
          700: "#004a97",
          800: "#033e7b",
          900: "#022d5a",
          950: "#021c38",
        },
      },
    },
  },
  livechat: {
    providers: {
      onecall: {
        params: {
          organisation: "40ff3000-1790-4577-8ba6-99f07c716a27",
          fallback: "whoson",
          departments: {
            default: {
              department: "one-click",
              form: "main-website",
            },
            platinum: {
              department: "platinum",
              form: "one-click",
            },
            payd: {
              department: "payd",
              form: "PAYD portal",
            },
            motor: {
              department: "one-click",
              form: "general-motor",
            },
            home: {
              department: "one-click",
              form: "general-home",
            },
            multiple: {
              department: "one-click",
              form: "multiple-portal-lobs",
            },
            renewalsMotor: {
              department: "one-click",
              form: "renewals-motor",
            },
            renewalsHome: {
              department: "one-click",
              form: "renewals-home",
            },
            retentions: {
              department: "one-click",
              form: "One click retentions",
            },
          },
        },
      },
    },
  },
};
