import logo from "./onecall-logo.svg";
export default {
  logo,
  brand: {
    awards: [
      {
        name: "Broker Insurance Award",
        src: "best-broker-insurance-award.svg",
      },
      {
        name: "Broker Insurance Award",
        src: "personal-insurance-broker-award.svg",
      },
    ],
  },
  renewalBenefits: {
    home: [
      "<strong>FREE</strong> Home emergency",
      "UK Based Contact Centre",
      "<strong>FREE</strong> Legal service",
      "Online Portal Available 24/7",
      "24 Hour Claims",
      "More than 130,000 5* Reviews",
    ],
    motor: [
      "<strong>FREE</strong> Basic Breakdown Membership",
      "Uninsured Driver Promise",
      "Online Portal Available 24/7",
      "<strong>FREE</strong> Legal Cover",
      "24 Hour Claims & Recovery Service",
      "More than 130,000 5* Reviews",
      "<strong>FREE</strong> Replacement Vehicle",
      "UK Based Contact Centre",
    ],
    chunk: 3,
  },
  features: {
    tradingName: false,
    stats: {
      percentage: 74,
      customers: 555375,
    },
  },
};
