import logo from "./oneprotect-logo.svg";
export default {
  logo,
  brand: {
    awards: [],
  },
  renewalBenefits: {
    home: [
      "<strong>FREE</strong> Legal service",
      "UK Based Contact Centre",
      "Online Portal Available 24/7",
      "24 Hour Claims",
    ],
    motor: [
      "<strong>FREE</strong> Legal Cover",
      "Uninsured Driver Promise",
      "Online Portal Available 24/7",
      "24 Hour Claims & Recovery Service",
      "UK Based Contact Centre",
    ],
    chunk: 3,
  },
  features: {
    tradingName: true,
    stats: {
      percentage: "",
      customers: "",
    },
    telephoneNumbers: {
      renewals: {
        motor: {
          inbound: "",
          outbound: "",
        },
        home: {
          inbound: "",
          outbound: "",
        },
      },
    },
    adjustments: {
      enhancementLevels: [
        {
          id: 3,
          name: "Hire Car Cover",
          prefix: "Guaranteed",
          basicLevel: 395,
        },
      ],
    },
    offers: {
      upsells: [7],
    },
  },
  theme: {
    preset: {
      primitive: {
        blue: {
          50: "#eef5fc",
          100: "#d7eaff",
          200: "#a7c6ff",
          300: "#7aa5f2",
          400: "#4f89e8",
          500: "#0157ad",
          600: "#004a9b",
          700: "#002447",
          800: "#001a33",
          900: "#000e1b",
          950: "#021c38",
        },
        red: {
          50: "#cac0c2",
          100: "#c9a4a7",
          200: "#b97272",
          300: "#af4d4f",
          400: "#b92a2e",
          500: "#bc0c15",
          600: "#ad0015",
          700: "#9b000f",
          800: "#8f0007",
          900: "#820000",
          950: "#400501",
        },
      },
    },
  },
  livechat: {
    providers: {
      onecall: {
        params: {
          organisation: "40ff3000-1790-4577-8ba6-99f07c716a27",
          fallback: "whoson",
          departments: {
            default: {
              department: "66d0754ce2ec7",
              form: "main-website",
            },
            platinum: {
              department: "platinum",
              form: "customer-portal",
            },
            motor: {
              department: "66d0754ce2ec7",
              form: "motor-portal",
            },
            home: {
              department: "66d0754ce2ec7",
              form: "hhi-portal",
            },
            multiple: {
              department: "66d0754ce2ec7",
              form: "multiple-portal-lobs",
            },
            renewalsMotor: {
              department: "66d0754ce2ec7",
              form: "renewals-motor",
            },
            renewalsHome: {
              department: "66d0754ce2ec7",
              form: "renewals-home",
            },
            retentions: {
              department: "66d0754ce2ec7",
              form: "One protect retentions",
            },
          },
        },
      },
    },
  },
};
